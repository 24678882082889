import user from '@/components/mixins/user'

export default {
  mixins: [user],
  data () {
    return {
      rolesPassportInteraction: ['admin', 'unit', 'manager'],
      rolesObjectInteraction: ['admin', 'unit', 'manager', 'auditor']
    }
  },
  computed: {
    isCanInteractionPassport () {
      // for passport
      return this.rolesPassportInteraction.includes(this.currentRole)
    },
    isCanInteractionObject () {
      // for object
      return this.rolesObjectInteraction.includes(this.currentRole)
    },
    isAllowSaveDelete () {
      //for passport and region in map
      const allowRoles = ['admin', 'manager']
      return allowRoles.includes(this.currentRole)
    }
  },
  methods: {
    isCanLoad (nameLocation) { // nameLocation - название вкладки на рус, например: "Документы"
      const locationsLoading = [this.$t('base.docs'), this.$t('base.images')]
      return [...this.rolesPassportInteraction, 'auditor', 'manager'].includes(this.currentRole) &&
        locationsLoading.includes(nameLocation)
    }
  }
}